import dashboard from "./admin/dash-admin";
import invite from "./admin/Invite";
import customVideo from "./admin/custom-video";
import adminMarketplace from "./marketplace/adminMarketplace";
import invoices from "./admin/invoices";
import Plans from "./admin/plans";
import coupon from "./admin/coupon";

import news from "./News";
export default [
  ...dashboard,
  ...invite,
  ...customVideo,
  ...adminMarketplace,
  ...news,
  ...Plans,
  ...invoices,
  ...coupon,
];
