export default [
  {
    title: "Invoices",
    icon: "ri-article-line",
    iconTO: true,
    route: "invoices",
    resource: "Invoice",
    action: "manage",
  },
];
