export default [
  {
    title: "Config Dash",
    icon: "SettingsIcon",
    children: [
      {
        title: "Custom Video",
        icon: "VideoIcon",
        route: "custom-video",
        resource: "Invoice",
        action: "manage",
      },
    ],
  },
];
