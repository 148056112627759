export default [
  {
    title: "Marketplace",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Code Source",
        icon: "CodeIcon",
        route: "adminMarketplace",
        resource: "Invoice",
        action: "manage",
      },
    ],
  },
];
