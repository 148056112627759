<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.first_name + " " + userData.last_name }}
        </p>

        <span v-if="userData.role != 'admin'">
          <b-button
            v-if="planCheck"
            style="font-size: 10px; padding: 3px !important; border-radius: 5px"
            variant="success"
            class="m-0 p-0 bg-success text-white"
          >
            pro plan
          </b-button>
          <b-button
            v-else
            style="font-size: 10px; padding: 3px !important; border-radius: 5px"
            variant="danger"
            class="m-0 p-0 text-white"
          >
            basic plan
          </b-button>
        </span>
        <span v-else class="user-status">
          {{ userData.role }}
        </span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.first_name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->

    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>  -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      to="/pages/0/user-profile"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      to="/pages/2/user-profile"
    >
      <feather-icon size="16" icon="DollarSignIcon" class="mr-50" />
      <span> Billing & Plan</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BLink,
  BButton,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
// import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import axios from "@axios";
import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { getAccessTokenFromCookie } from "@/auth/utils";
export default {
  components: {
    BLink,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
  },
  setup() {
    const data = getAccessTokenFromCookie();
    var userData = {};
    if (data) {
      userData = data[2];
    } else {
      userData = {};
    }

    return {
      avatarText,
      userData,
    };
  },
  computed: {
    planCheck() {
      return this.$store.getters.getplanCheck;
    },
  },
  methods: {
    async logout() {
      const data = getAccessTokenFromCookie();
      let keyUser = data[1].key;
      // Delete the token
      await axios
        .post("/logout", { keyUser })
        .then((response) => {
          //redirect to login if token isn't valid
          if (response.status == "Unauthorized") {
            deleteAccessTokenCookie();
            // Reset ability
            this.$ability.update(initialAbility);

            // Redirect to login page
            this.$router.push({ name: "auth-login" });
            return;
          }
          deleteAccessTokenCookie();
          // Reset ability
          this.$ability.update(initialAbility);
          // Redirect to login page
          this.$router.push({ name: "auth-login" });
        })
        .catch(console.error);
    },
  },
};
</script>
