export default [
  {
    title: "Keyword Manager",
    icon: "TypeIcon",
    resource: "Invoice",
    action: "manage",
    lock: true,
    children: [
      // {
      //   title: "Keyword Research",
      //   icon: "SearchIcon",
      //   route: "keyword-manager-keyword-research",
      //   resource: "Invoice",
      //   action: "manage",
      // },
      // {
      //   title: "Keyword Import",
      //   icon: "UploadIcon",
      //   route: "keyword-manager-keyword-import",
      //   resource: "Invoice",
      //   action: "manage",
      // },
      {
        title: "Keyword Search",
        icon: "SearchIcon",
        route: "keyword-manager-keyword-search",
        resource: "Invoice",
        action: "manage",
        lock: true
      },
      {
        title: "Keyword MAX",
        icon: "SearchIcon",
        route: "keyword-manager-keyword-search-max",
        resource: "Invoice",
        action: "manage",
        lock: true
      },
      {
        title: "Keyword Suggest",
        // icon: "SearchIcon",
        icon: "ri-search-eye-line",
        iconTO: true,
        route: "keyword-manager-suggestions",
        resource: "Invoice",
        action: "manage",
      },
    ],
  },
];
