export default [
  {
    title: "News",
    icon: "BellIcon",
    children: [
      {
        title: "Latest Updates",
        icon: "PlusSquareIcon",
        route: "adminNews",
        resource: "Invoice",
        action: "manage",
      },
    ],
  },
];