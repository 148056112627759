<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon size="30" v-if="!item.iconTO" :icon="item.icon || 'CircleIcon'" /> 
      <i :class="item.icon" style="margin-top: -10px;" v-else></i>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
      <span v-if="item.lock && !planCheck" style="display: flex; flex: 1; flex-direction: row-reverse;">
        <!-- <feather-icon  icon="LockIcon" style="width:16px !important;height:16px !important;" /> -->
      <b-button style="font-size:10px; padding:3px !important; "  variant="danger" class="m-0 p-0">Pro</b-button>
        <!-- <i class="ri-lock-line" style="font-size:18px; margin-bottom:5px"></i> -->
    </span>
    <span v-if="item.lock && planCheck"  style="display: flex; flex: 1; flex-direction: row-reverse;">
        <!-- <feather-icon icon="StarIcon" size="10" class="text-success feather feather-star stroke-current fill-current h-6 w-6" /> -->
      <b-button style="font-size:10px; padding:3px !important; "  variant="success" class="m-0 p-0">
        Pro
      </b-button>
    </span>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge ,BButton} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  computed: {
    planCheck() {
      return this.$store.getters.getplanCheck;
    },
  },
  components: {
    BLink,
    BBadge,
    BButton
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
