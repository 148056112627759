export default [
  {
    title: "Coupons",
    icon: "ri-coupon-line",
    iconTO: true,
    route: "coupons",
    resource: "Invoice",
    action: "manage",
  },
];
