export default [
    {
      title: "Discord",
      route: "discord-group",
      icon: "ri-discord-line",
      iconTO: true,
      resource: "Invoice",
      action: "manage",
      lock: true
    },
  ];