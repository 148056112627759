export default [
  {
    title: "Plans",
    icon: "ri-vip-crown-line",
    iconTO: true,
    route: "paypal-plans",
    resource: "Invoice",
    action: "manage",

  },
];
